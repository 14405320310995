/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://2ls32wmmbrg6raffbm2sjuwwqe.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:c458c41d-eba0-4fd1-868b-d99ad256e90e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_kopoh2EY3",
    "aws_user_pools_web_client_id": "7jocsli76b9dr4eb58cih979od",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "smartport1fd30e95c68846669d7fec4ba60d518e121012-tarifftest",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
